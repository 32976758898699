import React from 'react'
import { StaticImage } from "gatsby-plugin-image"


export default function Banner1() {
    return (
       
        <div align="center">
        <a 
        href="https://cursode.jabones.org" 
        target="_blank" 
        rel="noreferrer"        
        >

        <StaticImage
          src="../../static/images/uploads/banner1.jpg"
          width={600}
          height={600}
          quality={95}
          formats={["AUTO", "WEBP", "AVIF"]}
          alt="Curso de Jabones Artesanales"
          style={{ marginBottom: `1.45rem` }}
          /> 
          </a>
          </div>
        
    )
}


